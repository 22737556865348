import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Histoire from "@/views/Histoire.vue";
import Ordre from "@/views/Ordre.vue";
import Medaille from "@/views/Medaille.vue";
import Statut from "@/views/Statut.vue";
import Nouvelle from "@/views/Nouvelle.vue";
import Connexion from "@/views/Connexion.vue";
import Section from "@/views/Section.vue";
import Contact from "@/views/Contact.vue";
import Decore from "@/views/Decore.vue";
import Decore2 from "@/views/Decore2.vue";
import Article from "@/views/Article/Article.vue";
import CDM from "@/views/CDM.vue";
import SectionVue from "@/views/SectionVue/SectionVue.vue";
import Prix from "@/views/Prix.vue";
import Congres from "@/views/Congres.vue";
import CongresVue from "@/views/Congres/CongresVue.vue";
import Dashboard from "@/views/Dashboard.vue";
import ErrorPage from "@/views/Dashboard.vue";
import MemberDash from "@/views/Dashboard/MemberDash.vue";
import AdminDash from "@/views/Dashboard/AdminDash.vue";


const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
  {
    path: '/histoire-maritime',
    name: 'histoire',
    component: Histoire
  },
  {
    path: '/espace-membre/membre',
    name: 'MemberDash',
    component: MemberDash
  },
  {
    path: '/espace-membre/admin',
    name: 'AdminDash',
    component: AdminDash
  },
  {
    path: '/conseil-de-l-ordre',
    name: 'ordre',
    component: Ordre
  },
  {
    path: '/medaille-honneur',
    name: 'medaille',
    component: Medaille
  },
  {
    path: '/statuts-federation',
    name: 'statut',
    component: Statut
  },
  {
    path: '/nouvelles-de-la-federation',
    name: 'nouvelle',
    component: Nouvelle
  },
  {
    path: '/espace-membre',
    name: 'Connexion',
    component: Connexion
  },
  {
    path: '/sections',
    name: 'section',
    component: Section
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/les-decores',
    name: 'decore',
    component: Decore
  },
  {
    path: '/les-decores-2',
    name: 'decore2',
    component: Decore2
  },
  {
    path: '/nouvelles-de-la-federation/article/:id',
    name: 'article',
    component: Article
  },
  {
    path: '/centre-de-documentation-maritime',
    name: 'cdm',
    component: CDM
  },
  {
    path: '/sections/:id',
    name: 'SectionVue',
    component: SectionVue
  },
  {
    path: '/prix-litteraires',
    name: 'Prix',
    component: Prix
  },
  {
    path: '/congres-fnmm',
    name: 'Congres',
    component: Congres
  },
  {
    path: '/congres-fnmm/:id',
    name: 'CongresVue',
    component: CongresVue
  },
  {
    path: '/espace-membre/panel',
    name: 'Dashboard',
    component: Dashboard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
