<script>
  import NouvellesDash from "@/components/Dashboard/NouvellesDash.vue";
  import AccueilDash from "@/components/Dashboard/AccueilDash.vue";
  import SectionsDash from "@/components/Dashboard/SectionsDash.vue";
  import DecoresDash from "@/components/Dashboard/DecoresDash.vue";
  import DeciresDash from "@/components/Dashboard/DecoresDash.vue";
  import MegaDash from "@/components/Dashboard/MegaDash.vue";
  import UploadImage from "@/components/Dashboard/UploadImage.vue";
  import ActualiteSection from "@/components/Dashboard/ActualiteSection.vue";
  import Prix from "@/components/Dashboard/Prix.vue";
  import Contact from "@/components/Dashboard/Contact.vue";



  export default {
    name : 'Dashboard',
    components:{
      DeciresDash,
      NouvellesDash,
      AccueilDash,
      SectionsDash,
      DecoresDash,
      UploadImage,
      MegaDash,
      ActualiteSection,
      Prix,
      Contact,

    },
    data() {
      return {
        activeComponent: 'AccueilDash',  // Par défaut, afficher le composant AccueilDash
      };
    },
    methods: {
      logout() {
        localStorage.clear(); // Vider tout le localStorage
        this.$router.push('/'); // Rediriger vers la route '/'
      },
      setActiveComponent(componentName) {
        this.activeComponent = componentName;
      },
    }
  }
</script>

<template>
  <section class="w-full h-dvh flex flex-col overflow-y-hidden">
    <nav class="border-b w-full items-center px-6 py-2 fixed top-0 bg-white justify-between flex">
      <a href="/"><img src="@/assets/img/logo.png" class="w-14"></a>
      <a href="/" @click.prevent="logout" class="bg-red-400 text-white px-4 py-2 rounded">Déconnexion</a>

      
    </nav>
    <section class="flex w-full h-full items-center view">
      <div class="sidebar flex flex-col border-r w-full h-full overflow-y-hidden pt-6 gap-2">
        <a href="/" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Retour site</a>
        <hr class="flex w-full border my-4">
        <button @click="setActiveComponent('AccueilDash')" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Accueil</button>
        <button @click="setActiveComponent('MegaDash')" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Espace FNMM</button>
        <button @click="setActiveComponent('NouvellesDash')" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Nouvelles Fédérations</button>
        <button @click="setActiveComponent('SectionsDash')" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Sections</button>
        <button @click="setActiveComponent('ActualiteSection')" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Actualité Section</button>
        <button @click="setActiveComponent('DecoresDash')" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Les Décorés</button>
        <button @click="setActiveComponent('Prix')" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Prix littéraire</button>
        <button @click="setActiveComponent('Contact')" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Contact</button>
        <button @click="setActiveComponent('UploadImage')" class="w-full px-6 py-4 justify-center items-center flex bg-neutral-100 hover:bg-neutral-200">Images</button>

      </div>
      <AccueilDash v-if="activeComponent === 'AccueilDash'"></AccueilDash>
      <NouvellesDash v-if="activeComponent === 'NouvellesDash'"></NouvellesDash>
      <SectionsDash v-if="activeComponent === 'SectionsDash'"></SectionsDash>
      <DeciresDash v-if="activeComponent === 'DecoresDash'"></DeciresDash>
      <MegaDash v-if="activeComponent === 'MegaDash'"></MegaDash>
      <UploadImage v-if="activeComponent === 'UploadImage'"></UploadImage>
      <ActualiteSection v-if="activeComponent === 'ActualiteSection'"></ActualiteSection>
      <Contact v-if="activeComponent === 'Contact'"></Contact>
      <Prix v-if="activeComponent === 'Prix'"></Prix>


    </section>
  </section>
</template>

<style scoped>
  .view{
    padding-top: 70px;
  }
  .sidebar{
    max-width: 300px;
  }
</style>