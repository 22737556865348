<script>
import axios from 'axios';

export default {
  name: 'decores',
  data() {
    return {
      isMobileVisible: false,
      research: '',
      searchResults: [],
      timer: null,
      people: [], // Initialisation de people
      documents: [], // Initialisation pour stocker les documents
      reponse: {
        NOM: '', 
        Chevalier: '',
        Officier: '',
        Commandeur: '',
        Autres: ''
      }
    };
  },
  methods: {
    async search() {
      try {
        const response = await axios.post('https://api.meritemaritime-fnmm.com/search2/', {
          research: this.research
        });
        console.log('Réponse de l\'API:', response.data);
        this.people = response.data; 
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    },
    async fetchDocuments() {
      try {
        const response = await axios.get('https://api.meritemaritime-fnmm.com/backoffice/getpdfsection');
        console.log('Réponse de l\'API pour les documents:', response.data);
        this.documents = response.data; // Stocker les documents dans la propriété data
      } catch (error) {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    },
    startTimer() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.search, 500);
    }
  },
  created() {
    this.fetchDocuments(); // Appeler fetchDocuments lors de la création du composant
  }
}
</script>


<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Les décorés MM</h1>
    </div>
  </div>
  <div class="widget max-w-screen-lg flex bg-neutral-100 rounded-xl p-6 items-center gap-6 mx-6">
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-triangle-alert"><path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3"/><path d="M12 9v4"/><path d="M12 17h.01"/></svg>
    <span>La liste des personnes décorées et médaillées est incomplète; cependant, elle sera mise à jour régulièrement afin de refléter les informations les plus récentes.</span>
  </div>
  <section class="flex w-full mb-24 flex-col items-center justify-center gap-8 px-6">
<!--    -->


<div class="files flex w-full items-center flex-wrap justify-center gap-2 max-w-screen-lg">
      <a v-for="document in documents" :key="document.name" :href="document.url" target="_blank" class="flex items-center px-4 py-2 gap-2 border rounded bg-white text-blue-600 hover:bg-gray-200">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text">
          <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"/>
          <path d="M14 2v4a2 2 0 0 0 2 2h4"/>
          <path d="M10 9H8"/>
          <path d="M16 13H8"/>
          <path d="M16 17H8"/>
        </svg>
        {{ document.name }}
      </a>
    </div>


<!--    -->
    <div class="search flex w-full max-w-screen-lg">
    <input type="text" class="flex w-full bg-neutral-100 outline-0 p-4 rounded" id="search" v-model="research" placeholder="Rechercher un nom" @input="startTimer">
    </div>
    <hr class="flex w-full border max-w-screen-lg">
    <h1>Résultat</h1>
    <div class="result flex w-full flex-col max-w-screen-lg gap-8">
    

      <div v-for="person in people" :key="person.id" class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
    <h1 class="text-2xl">{{ person.NOM }}</h1>
    <hr class="border w-full flex">
    <div class="sections flex w-full">
      <div class="box p-2 flex flex-col w-full">
        <h1>Chevalier</h1>
        <hr class="border w-full flex">
        <span>{{ person.Chevalier || '-' }}</span>
      </div>
      <div class="box p-2 flex flex-col w-full">
        <h1>Officier</h1>
        <hr class="border w-full flex">
        <span>{{ person.Officier || '-' }}</span>
      </div>
      <div class="box p-2 flex flex-col w-full">
        <h1>Commandeur</h1>
        <hr class="border w-full flex">
        <span>{{ person.Commandeur || '-' }}</span>
      </div>

      <div class="box p-2 flex flex-col w-full">
        <h1>Autres</h1>
        <hr class="border w-full flex">
        <span>{{ person.Autres || '-' }}</span>
      </div>
    </div>
  </div>
      


    </div>
  </section>
</template>






<style scoped>
@media (max-width: 800px) {
  .widget{
    flex-direction: column;
    text-align: center;
  }
  .sections{
    flex-direction: column;
  }

  .sections hr{
    display: none;
  }
}

@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}
</style>

