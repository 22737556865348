<script>
import axios from 'axios';

export default {
  name: "DeciresDash",
  data() {
    return {
      formData: {
        name: '',
        year: '',
        quartier: '',
        fonction: '',
        id: ''
      },
      formData2: {
        name: '',
        chevalier: '',
        officier: '',
        commandeur: '',
        autre: '',
        id: ''
      },
      pdfNames: [], // Pour stocker les noms et IDs des PDFs
      searchQuery: '', // Pour stocker le texte de recherche
      selectedType: '', // Pour stocker la sélection des boutons radio
      searchResults: [] // Stocke les résultats de la recherche
    };
  },
  mounted() {
    this.fetchPdfNames(); // Appeler la méthode pour récupérer les noms des PDFs lorsque le composant est monté
  },
  methods: {
    // Méthode pour récupérer les noms des PDFs depuis l'API
    fetchPdfNames() {
      axios.get('https://api.meritemaritime-fnmm.com/backoffice/getpdfsection')
        .then(response => {
          this.pdfNames = response.data.map(pdf => ({
            id: pdf.id,
            name: pdf.name
          }));
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des noms des PDFs:', error);
        });
    },

    // Méthode pour gérer l'upload du fichier PDF
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === 'application/pdf') {
        const formData = new FormData();
        formData.append('pdf', file);

        axios.post('https://api.meritemaritime-fnmm.com/backoffice/createpdfdecore', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log('PDF téléchargé avec succès', response.data);
          this.fetchPdfNames(); // Mettre à jour la liste des PDFs après l'upload
        })
        .catch(error => {
          console.error('Erreur lors du téléchargement du PDF', error);
        });
      } else {
        alert('Veuillez sélectionner un fichier PDF.');
      }
    },

    // Méthode pour supprimer un PDF par ID
    deletePdf(id) {
      axios.delete('https://api.meritemaritime-fnmm.com/backoffice/pdfdeletesection', {
        data: { id }
      })
      .then(response => {
        console.log('PDF supprimé avec succès', response.data);
        this.fetchPdfNames(); // Mettre à jour la liste des PDFs après la suppression
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du PDF', error);
      });
    },

    // Méthode pour envoyer les données du formulaire
    submitForm() {
      axios.post('https://api.meritemaritime-fnmm.com/backoffice/adddecore', this.formData)
        .then(response => {
          console.log('Données ajoutées avec succès:', response.data);
          // Réinitialiser le formulaire ou afficher un message de succès
          this.formData = { name: '', year: '', quartier: '', fonction: '', id: '' };
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout des données:', error);
        });
    },

    submitForm3() {
      axios.post('https://api.meritemaritime-fnmm.com/backoffice/updatedecore2', this.formData)
        .then(response => {
          console.log('Données ajoutées avec succès:', response.data);
          // Réinitialiser le formulaire ou afficher un message de succès
          this.formData = { name: '', chevalier: '', officier: '', commandeur: '', autre: '', id: '' };

        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout des données:', error);
        });
    },


    // Méthode pour envoyer les données du formulaire 2
    submitForm2() {
      axios.post('https://api.meritemaritime-fnmm.com/backoffice/adddecore2', this.formData2)
        .then(response => {
          console.log('Données ajoutées avec succès:', response.data);
          // Réinitialiser le formulaire ou afficher un message de succès
          this.formData2 = { name: '', chevalier: '', officier: '', commandeur: '', autre: '', id: '' };
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout des données:', error);
        });
    },

        // Méthode pour envoyer les données du formulaire 2
        submitForm4() {
      axios.post('https://api.meritemaritime-fnmm.com/backoffice/updatedecore', this.formData2)
        .then(response => {
          console.log('Données ajoutées avec succès:', response.data);
          // Réinitialiser le formulaire ou afficher un message de succès
          this.formData2 = { name: '', year: '', quartier: '', fonction: '', id: '' };
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout des données:', error);
        });
    },
    // Méthode pour rechercher des articles en fonction des critères
    search() {
      if (this.searchQuery && this.selectedType) {
        axios.get(`https://api.meritemaritime-fnmm.com/search3/${this.selectedType}`, {
          params: { query: this.searchQuery }
        })
        .then(response => {
          this.searchResults = response.data; // Met à jour les résultats de recherche
          console.log('Résultats de la recherche:', response.data);
        })
        .catch(error => {
          console.error('Erreur lors de la recherche:', error);
        });
      } else {
        alert('Veuillez entrer une recherche et sélectionner un type.');
      }
    },

    // Méthode pour éditer un résultat
    editResult(result) {
      if (this.selectedType === 'MH') {
        this.formData = {
          name: result.Prenom,
          year: result.Annee,
          quartier: result.Quartier,
          fonction: result.Fonction,
          id: result.id
        };
      } else if (this.selectedType === 'MM') {
        this.formData2 = {
          name: result.NOM,
          chevalier: result.Chevalier,
          officier: result.Officier,
          commandeur: result.Commandeur,
          autre: result.Autres,
          id: result.id

        };
      }
    }
  }
};
</script>

<template>
  <div class="sidebar overflow-y-auto overflow-x-hidden flex-col flex gap-2 w-full h-full border-r p-6">
    <div class="search">
      <input type="text" name="search" id="search" placeholder="Rechercher un article" v-model="searchQuery" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
      <hr class="w-full flex border my-4">

      <!-- Radio buttons -->
      <div class="radio-buttons flex gap-4">
        <label>
          <input type="radio" value="MM" v-model="selectedType"> MM
        </label>
        <label>
          <input type="radio" value="MH" v-model="selectedType"> MH
        </label>
      </div>
      
      <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4" @click="search">Rechercher</button>

      <!-- Résultats de la recherche -->
      <div class="search-results mt-4">
        <div v-for="(result, index) in searchResults" :key="index" class="flex justify-between items-center border-b py-2">
          <div v-if="selectedType === 'MH'">
            <p>{{ result.Prenom }} - {{ result.Annee }} - {{ result.Quartier }} - {{ result.Fonction }}</p>
          </div>
          <div v-else-if="selectedType === 'MM'">
            <p>{{ result.NOM }} - Chevalier: {{ result.Chevalier }} - Officier: {{ result.Officier }} - Commandeur: {{ result.Commandeur }} - Autres: {{ result.Autres }}</p>
          </div>
          <button class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400" @click="editResult(result)">Éditer</button>
        </div>
      </div>
    </div>
  </div>

  <section class="flex size-full flex-col p-6 items-center">
    <div class="manual flex flex-col gap-6 max-w-screen-lg mb-6">
      <h1>Mérite Maritime</h1>
      <input type="text" name="name" id="name" v-model="formData2.name" placeholder="Nom complet" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
      <div class="infos flex items-center gap-6">
        <input type="text" name="Chevalier" id="chevalier" v-model="formData2.chevalier" placeholder="Chevalier" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <input type="text" name="Officier" id="officier" v-model="formData2.officier" placeholder="Officier" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <input type="text" name="Commandeur" id="commandeur" v-model="formData2.commandeur" placeholder="Commandeur" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <input type="text" name="autre" id="autre" v-model="formData2.autre" placeholder="Autres décorations" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <input type="hidden" name="id" id="id" v-model="formData2.id" placeholder="Id">

      </div>
      <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4" @click="submitForm2">Crée</button>

      <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4" @click="submitForm4">Modifier</button>

    </div>

    <div class="manual flex flex-col gap-6 max-w-screen-lg">
      <h1>Médaille d'honneur</h1>
      <input type="text" name="name" id="name" v-model="formData.name" placeholder="Nom complet" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
      <div class="infos flex items-center gap-6">
        <input type="text" name="year" id="year" v-model="formData.year" placeholder="Année" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <input type="text" name="quartier" id="quartier" v-model="formData.quartier" placeholder="Quartier" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <input type="text" name="fonction" id="fonction" v-model="formData.fonction" placeholder="Fonction" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <input type="hidden" name="id" id="id" v-model="formData.id" placeholder="Id">
      </div>
      <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4" @click="submitForm">Crée</button>

      <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4" @click="submitForm3">Modifier</button>

    </div>
  </section>
</template>
