<template>
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold mb-4">Liste de Contacts</h1>
      
      <!-- Conteneur scrollable pour la liste des contacts -->
      <div class="max-h-[400px] overflow-y-auto mb-4">
        <!-- Boucle pour afficher les emails -->
        <ul v-if="contacts.length" class="space-y-4">
          <li v-for="(contact, index) in contacts" :key="contact.id" class="p-4 border border-gray-200 rounded-md shadow-sm flex justify-between items-center">
            <span :class="{'font-semibold': contact.opened}" class="text-lg">
              {{ contact.email }}
              <span v-if="contact.opened" class="text-green-500 ml-2">(Déjà ouvert)</span>
            </span>
            <button
              class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              @click="showDetails(index)"
            >
              Détails
            </button>
          </li>
        </ul>
  
        <!-- Message si la liste est vide -->
        <div v-else class="text-gray-500">Aucun contact trouvé.</div>
      </div>
  
      <!-- Modal pour afficher les détails d'un contact -->
      <div v-if="selectedContact" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div class="bg-white p-8 rounded-md shadow-md w-[80vw] max-w-4xl">
          <h2 class="text-xl font-bold mb-4">Détails du Contact</h2>
          <p><strong>Email:</strong> {{ selectedContact.email }}</p>
          <p><strong>Nom:</strong> {{ selectedContact.lastname }}</p>
          <p><strong>Objet:</strong> {{ selectedContact.object }}</p>
          <p><strong>Message:</strong> <pre>{{ selectedContact.message }}</pre></p>
          <button
            class="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
            @click="closeDetails"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        contacts: [], // Liste de contacts
        selectedContact: null, // Contact sélectionné pour afficher les détails
      };
    },
    mounted() {
      // Appel API pour récupérer la liste des contacts
      this.getContacts();
    },
    methods: {
      async getContacts() {
        try {
          const response = await axios.get('https://api.meritemaritime-fnmm.com/contact/list');
          
          // Vérifier que la réponse est correcte et contient les données
          if (response.data.success) {
            // Accéder aux données via la clé 'data' et ajouter le champ 'opened'
            this.contacts = response.data.data.map(contact => ({
              ...contact,
              opened: false, // Initialement, aucun contact n'est ouvert
            }));
          } else {
            console.error('Erreur : Le succès de la réponse est faux.');
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des contacts:', error);
        }
      },
      showDetails(index) {
        // Marquer le contact comme ouvert
        this.contacts[index].opened = true;
        this.selectedContact = this.contacts[index];
      },
      closeDetails() {
        this.selectedContact = null;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Ajouter un peu de style personnalisé si nécessaire */
  pre {
    white-space: pre-wrap; /* Permet d'afficher les sauts de ligne dans le message */
  }
  </style>
  