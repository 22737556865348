<script>
export default {
  name: "Cta"
}
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Navigation rapide</h1>
    </div>
  </div>
  <section class="flex w-full items-center justify-center p-6">
    <div class="containt flex w-full gap-8 max-w-screen-lg mb-24">
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <h1 class="text-2xl">Annuaire FNMM-MH</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-52"></p>
        <a href="https://annuaire.fnmm.fr/" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg></a>
      </div>
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <h1 class="text-2xl">Histoire Mérite Maritime</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-52">Plongez dans l'histoire captivante et riche de la Fédération du Mérite Maritime, et découvrez les moments clés.</p>
        <a href="/histoire-maritime" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg></a>
      </div>
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <h1 class="text-2xl">Les décorés MM-MH</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-52">Rechercher une personne ayant été décorés ou médaillés de 1930 à 2021.</p>
        <a href="/les-decores" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg></a>
      </div>
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <h1 class="text-2xl">Sections</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-52">Découvrez les membres de chaque section de notre fédération nationale du mérite maritime.</p>
        <a href="/sections" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg></a>
      </div>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 800px){
  .containt{
    flex-direction: column;
  }
}
</style>