<template>
    <div class="p-6 bg-gray-100 min-h-screen">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Section des Listes -->
        <div>
          <!-- Liste des Prix -->
          <div class="mb-6 mt-12">
            <h1 class="text-3xl font-bold mb-4">Liste des Prix</h1>
          </div>
  
          <div class="max-w-md mx-auto mb-6">
            <ul class="space-y-4 max-h-96 overflow-y-auto bg-white shadow-md rounded-lg p-4">
              <li v-for="item in prixList" :key="item.id" class="flex justify-between items-center p-4 border-b border-gray-200">
                <span class="text-lg font-semibold">{{ item.name }}</span>
                <button @click="editItem(item.id)" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition">Éditer</button>
              </li>
            </ul>
          </div>
  
          <!-- Détails des Items -->
          <div v-if="selectedItems.length" class="max-w-md mx-auto mb-6">
            <h2 class="text-2xl font-bold mb-4">Détails des Items</h2>
            <div class="bg-white shadow-md rounded-lg overflow-y-auto max-h-80">
              <ul class="space-y-4 p-4">
                <li v-for="item in selectedItems" :key="item.id_prix" class="flex items-center space-x-4 border-b border-gray-200 pb-4">
                  <img :src="item.img" alt="Image du Prix" class="w-16 h-16 object-cover rounded-md" />
                  <div>
                    <p class="text-lg font-semibold">{{ item.nom }}</p>
                  </div>
                  <button @click="editItemDetails(item)" class="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition">Éditer</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
  
        <!-- Section des Formulaires -->
        <div>
          <!-- Formulaire pour créer un prix -->
          <div class="max-w-md mx-auto mb-6 mt-12">
            <h2 class="text-2xl font-bold mb-4">Ajouter un Prix</h2>
            <form @submit.prevent="createPrix" class="bg-white shadow-md rounded-lg p-4">
              <div class="mb-4">
                <label for="priceName" class="block text-gray-700 font-medium mb-2">Nom du Prix</label>
                <input
                  v-model="newPrixName"
                  id="priceName"
                  type="text"
                  class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Entrez le nom du prix"
                  required
                />
              </div>
              <button
                type="submit"
                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
              >
                Créer
              </button>
            </form>
          </div>
  
          <!-- Formulaire pour créer un item -->
          <div v-if="!editingItem && selectedPrixId" class="max-w-md mx-auto mb-6 mt-12">
            <h2 class="text-2xl font-bold mb-4">Ajouter un Item</h2>
            <form @submit.prevent="createItem" class="bg-white shadow-md rounded-lg p-4">
              <div class="mb-4">
                <label for="itemName" class="block text-gray-700 font-medium mb-2">Nom de l'Item</label>
                <input
                  v-model="newItemName"
                  id="itemName"
                  type="text"
                  class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Entrez le nom de l'item"
                  required
                />
              </div>
              <div class="mb-4">
                <label for="itemImg" class="block text-gray-700 font-medium mb-2">URL de l'Image</label>
                <input
                  v-model="newItemImg"
                  id="itemImg"
                  type="text"
                  class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Entrez l'URL de l'image"
                  required
                />
              </div>
              <input type="hidden" v-model="selectedPrixId" />
              <button
                type="submit"
                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
              >
                Créer Item
              </button>
            </form>
          </div>
  
          <!-- Formulaire pour éditer un item -->
          <div v-if="editingItem" class="max-w-md mx-auto mt-12">
            <h2 class="text-2xl font-bold mb-4">Éditer l'Item</h2>
            <form @submit.prevent="updateItem" class="bg-white shadow-md rounded-lg p-4">
              <div class="mb-4">
                <label for="editItemName" class="block text-gray-700 font-medium mb-2">Nom de l'Item</label>
                <input
                  v-model="editingItem.nom"
                  id="editItemName"
                  type="text"
                  class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Entrez le nom de l'item"
                  required
                />
              </div>
              <div class="mb-4">
                <label for="editItemImg" class="block text-gray-700 font-medium mb-2">URL de l'Image</label>
                <input
                  v-model="editingItem.img"
                  id="editItemImg"
                  type="text"
                  class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Entrez l'URL de l'image"
                  required
                />
              </div>
              <input type="hidden" v-model="editingItem.id" />

              <button
                type="submit"
                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
              >
                Mettre à jour
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        prixList: [],
        selectedItems: [],
        newPrixName: '',
        newItemName: '',
        newItemImg: '',
        selectedPrixId: null,
        editingItem: null
      };
    },
    async created() {
      try {
        const response = await axios.get('https://api.meritemaritime-fnmm.com/prix/list');
        this.prixList = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    },
    methods: {
      async createPrix() {
        if (!this.newPrixName.trim()) return;
  
        try {
          await axios.post('https://api.meritemaritime-fnmm.com/prix/create', { name: this.newPrixName });
          this.newPrixName = '';
          const response = await axios.get('https://api.meritemaritime-fnmm.com/prix/list');
          this.prixList = response.data;
        } catch (error) {
          console.error('Erreur lors de la création du prix:', error);
        }
      },
      async createItem() {
        if (!this.newItemName.trim() || !this.newItemImg.trim() || !this.selectedPrixId) return;
  
        try {
          await axios.post('https://api.meritemaritime-fnmm.com/items/create', {
            name: this.newItemName,
            img: this.newItemImg,
            id_prix: this.selectedPrixId
          });
          this.newItemName = '';
          this.newItemImg = '';
          const response = await axios.get(`https://api.meritemaritime-fnmm.com/prix/edit/list/${this.selectedPrixId}`);
          this.selectedItems = response.data.items;
        } catch (error) {
          console.error('Erreur lors de la création de l\'item:', error);
        }
      },
      async editItem(id) {
        try {
          const response = await axios.get(`https://api.meritemaritime-fnmm.com/prix/edit/list/${id}`);
          this.selectedItems = response.data.items;
          this.selectedPrixId = id;
          this.editingItem = null; // Cache le formulaire de création d'item
        } catch (error) {
          console.error('Erreur lors de la récupération des détails:', error);
        }
      },
      editItemDetails(item) {
        this.editingItem = { ...item };
      },
      async updateItem() {
  
        try {
          await axios.put(`https://api.meritemaritime-fnmm.com/items/update/${this.editingItem.id}`, {
            name: this.editingItem.nom,
            img: this.editingItem.img,
            id_prix: this.editingItem.id_prix
          });
          this.editingItem = null;
          const response = await axios.get(`https://api.meritemaritime-fnmm.com/prix/edit/list/${this.selectedPrixId}`);
          this.selectedItems = response.data.items;
        } catch (error) {
          console.error('Erreur lors de la mise à jour de l\'item:', error);
        }
      },
      logInputValue() {
        console.log(this.editingItem.id_prix);
      }
    }
  };
  </script>
  