<template>
  <div class="p-6 max-w-4xl mx-auto flex flex-wrap lg:flex-nowrap space-x-4">
    <!-- Formulaire d'upload d'image -->
    <div class="w-full lg:w-1/2 mb-8 lg:mb-0">
      <h2 class="text-2xl font-bold mb-4">Télécharger une image</h2>
      <form @submit.prevent="uploadImage" class="bg-white p-6 rounded-lg shadow-md">
        <div class="mb-4">
          <label for="name" class="block text-gray-700 font-medium mb-1">Nom de l'image:</label>
          <input v-model="name" type="text" id="name" required class="w-full border border-gray-300 rounded-lg px-3 py-2"/>
        </div>

        <div class="mb-4">
          <label for="image" class="block text-gray-700 font-medium mb-1">Sélectionner une image:</label>
          <input type="file" @change="handleFileUpload" id="image" required class="w-full border border-gray-300 rounded-lg px-3 py-2"/>
        </div>

        <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600">Envoyer</button>
      </form>

      <!-- Messages de succès ou d'erreur -->
      <div v-if="successMessage" class="mt-4 p-4 bg-green-100 border border-green-300 text-green-800 rounded-lg">
        <p>{{ successMessage }}</p>
      </div>

      <div v-if="errorMessage" class="mt-4 p-4 bg-red-100 border border-red-300 text-red-800 rounded-lg">
        <p>{{ errorMessage }}</p>
      </div>

  
    </div>

    <!-- Liste des images -->
    <div class="w-full lg:w-1/2">
      <h2 class="text-2xl font-bold mb-4">Liste des images</h2>
      <input v-model="searchQuery" type="text" placeholder="Rechercher par nom..." class="w-full border border-gray-300 rounded-lg px-3 py-2"/>

      <div v-if="filteredImages.length > 0" class="image-list space-y-4 overflow-y-auto h-96">
        <ul>
          <li v-for="image in filteredImages" :key="image.urlimage" class="bg-white p-4 rounded-lg shadow-md flex items-center space-x-4">
            <img :src="image.urlimage" :alt="image.name" class="w-32 h-32 object-cover rounded-lg"/>
            <div class="flex-1">
              <p class="text-lg font-semibold">{{ image.name }}</p>
            </div>
            <button @click="copyToClipboard(image.urlimage)" class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600">Copier l'URL</button>
          </li>
        </ul>
      </div>
      <div v-else class="mt-4 p-4 bg-gray-100 border border-gray-300 text-gray-800 rounded-lg">
        <p>Aucune image trouvée.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '', // Nom de l'image
      file: null, // Fichier image
      successMessage: '', // Message de succès
      errorMessage: '', // Message d'erreur
      images: [], // Liste des images
      searchQuery: '' // Requête de recherche
    };
  },
  mounted() {
    this.fetchImages(); // Appel de l'API lorsque le composant est monté
  },
  computed: {
    filteredImages() {
      if (!this.searchQuery) {
        return this.images;
      }
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      return this.images.filter(image => image.name.toLowerCase().includes(lowerCaseQuery));
    }
  },
  methods: {
    // Méthode pour gérer la sélection du fichier
    handleFileUpload(event) {
      this.file = event.target.files[0]; // Récupérer le fichier sélectionné
    },

    // Méthode pour envoyer le fichier et les données à l'API
    async uploadImage() {
      if (!this.file || !this.name) {
        this.errorMessage = "Veuillez remplir tous les champs.";
        return;
      }

      const formData = new FormData();
      formData.append('img', this.file); // Ajouter l'image
      formData.append('name', this.name); // Ajouter le nom

      try {
        const response = await axios.post('https://api.meritemaritime-fnmm.com/backoffice/uploadimage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data' // Spécifier que le contenu est un formulaire multipart
          }
        });

        // En cas de succès
        this.successMessage = `Image téléchargée avec succès. ID: ${response.data.imageId}`;
        this.errorMessage = '';
        this.name = '';
        this.file = null;
        this.fetchImages(); // Recharger la liste des images après téléchargement
      } catch (error) {
        // Gérer les erreurs
        console.error('Erreur lors du téléchargement de l\'image:', error);
        this.errorMessage = 'Erreur lors du téléchargement de l\'image.';
        this.successMessage = '';
      }
    },

    async fetchImages() {
      try {
        const response = await axios.get('https://api.meritemaritime-fnmm.com/backoffice/images');
        // Assurez-vous que les images ont une propriété `createdAt` ou une autre propriété de date
        this.images = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Trier les images par date décroissante
      } catch (error) {
        console.error('Erreur lors de la récupération des images :', error);
        this.errorMessage = 'Erreur lors de la récupération des images.';
      }
    },

    // Méthode pour copier l'URL de l'image dans le presse-papiers
    copyToClipboard(url) {
      navigator.clipboard.writeText(url).then(() => {
      }).catch(err => {
        console.error('Erreur lors de la copie de l\'URL :', err);
        alert('Erreur lors de la copie de l\'URL');
      });
    }
  }
};
</script>

<style scoped>
/* Pour ajuster le positionnement du formulaire et de la liste d'images */
@media (min-width: 1024px) {
  .image-list {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>
