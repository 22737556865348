<template>
    <div class="flex container mx-auto p-6 space-x-6 mt-8">
      <!-- Liste des sections à gauche -->
      <div class="flex-1 max-w-md overflow-y-auto bg-white shadow-md p-4 rounded-lg h-screen">
        <h1 class="text-2xl font-bold mb-6">Liste des sections</h1>
        <ul class="space-y-4">
          <li v-for="(section, index) in sections" :key="index" class="bg-gray-100 p-4 rounded-lg flex justify-between items-center">
            <span class="text-lg">{{ section.nom }}</span>
            <button 
              @click="editSection(section)" 
              class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
              Éditer
            </button>
          </li>
        </ul>
      </div>
  
      <!-- Formulaire à droite -->
      <div class="flex-1 max-w-md bg-white shadow-md rounded-lg mt-8 edit-section-container">
        <div v-if="actualite" class="scrollable-content">
          <h2 class="text-xl font-semibold mb-4">Éditer l'actualité</h2>
          <form @submit.prevent="submitForm" class="space-y-4">
            <div>
              <label for="text" class="block text-gray-700 font-medium mb-2">Texte :</label>
              <textarea 
                v-model="actualite.text" 
                id="text" 
                rows="4" 
                class="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
            <div>
              <label for="img" class="block text-gray-700 font-medium mb-2">Image :</label>
              <input 
                type="text" 
                v-model="imageUrl" 
                @input="updateImagePreview"
                id="img" 
                class="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div v-if="imagePreview" class="mt-4">
                <img :src="imagePreview" alt="Prévisualisation" class="w-full h-auto max-h-64 object-cover rounded-lg shadow-md" />
              </div>
            </div>
            <div>
              <label for="pdf" class="block text-gray-700 font-medium mb-2">Télécharger un PDF :</label>
              <input 
                type="file" 
                @change="handleFileUpload"
                id="pdf" 
                class="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div v-if="pdfPreview" class="mt-4">
                <p class="text-gray-700">PDF sélectionné : {{ pdfPreviewName }}</p>
                <iframe :src="pdfPreview" class="w-full h-64 mt-2 border border-gray-300 rounded-lg"></iframe>
              </div>
            </div>
            <button 
              type="submit" 
              class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors">
              Enregistrer
            </button>
          </form>
        </div>
        <div v-else>
          <p class="text-gray-500">Sélectionne une section pour voir et éditer les détails.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        sections: [], // Stockage des sections récupérées
        actualite: null, // Stocker l'actualité pour édition
        imageUrl: '', // URL de l'image
        imagePreview: '', // URL de la prévisualisation de l'image
        pdfFile: null, // Fichier PDF sélectionné
        pdfPreview: '', // URL du fichier PDF pour prévisualisation
        pdfPreviewName: '' // Nom du fichier PDF pour affichage
      };
    },
    methods: {
      async fetchSections() {
        try {
          const response = await axios.post('https://api.meritemaritime-fnmm.com/section/list');
          this.sections = response.data;
        } catch (error) {
          console.error('Erreur lors de la récupération des sections:', error);
        }
      },
      async editSection(section) {
        const idSectionFamily = section.id_section_familly;
  
        localStorage.setItem('id_section_familly', idSectionFamily);
  
        try {
          // Appelle l'API pour récupérer les actualités de la section
          const response = await axios.get(`https://api.meritemaritime-fnmm.com/backoffice/actualitesection?id_section_familly=${idSectionFamily}`);
          
          // Récupère la première actualité de la réponse et la stocke pour l'édition
          this.actualite = response.data[0];
          this.imageUrl = this.actualite.img; // Initialiser l'URL de l'image
          this.imagePreview = this.actualite.img; // Initialiser la prévisualisation de l'image
          this.pdfPreview = this.actualite.pdf; // Initialiser la prévisualisation du PDF
          this.pdfPreviewName = this.actualite.pdf ? this.actualite.pdf.split('/').pop() : ''; // Nom du PDF
        } catch (error) {
          console.error('Erreur lors de l\'appel API:', error);
        }
      },
      updateImagePreview() {
        this.imagePreview = this.imageUrl; // Met à jour la prévisualisation lorsque l'URL change
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
          this.pdfFile = file;
          this.pdfPreview = URL.createObjectURL(file); // Crée une URL pour prévisualiser le PDF
          this.pdfPreviewName = file.name; // Nom du fichier pour affichage
        } else {
          alert('Veuillez sélectionner un fichier PDF.');
          this.pdfFile = null;
          this.pdfPreview = '';
          this.pdfPreviewName = '';
        }
      },
      async submitForm() {
        const idSectionFamily = localStorage.getItem('id_section_familly');
        
        if (!idSectionFamily || !this.actualite) {
          console.error('ID de section de famille ou données de l\'actualité manquants.');
          return;
        }
  
        const formData = new FormData();
        formData.append('id_section_familly', idSectionFamily);
        formData.append('text', this.actualite.text);
        formData.append('img', this.imageUrl);
  
        if (this.pdfFile) {
          formData.append('pdf', this.pdfFile);
        }
  
        try {
          const response = await axios.put('https://api.meritemaritime-fnmm.com/backoffice/updateActualite', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
  
          console.log('Réponse du serveur:', response.data);
          alert('Actualité mise à jour avec succès.');
        } catch (error) {
          console.error('Erreur lors de la mise à jour:', error);
          alert('Erreur lors de la mise à jour de l\'actualité.');
        }
      }
    },
    created() {
      this.fetchSections(); // Récupère les sections lors de la création du composant
    }
  };
  </script>
  
  
  <style scoped>
  /* Ajoute du style si nécessaire */
  textarea {
    resize: vertical;
  }
  iframe {
    border: none;
  }
  .edit-section-container {
  max-height: 80vh; /* Limite la hauteur maximale à 80% de la hauteur de la fenêtre */
  overflow-y: auto; /* Ajoute une barre de défilement verticale si nécessaire */
}

.scrollable-content {
  padding: 16px; /* Ajoute un peu de padding pour que le contenu ne touche pas les bords */
}

  </style>
  